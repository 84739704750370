* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Helvetica", serif;
}

.card {
  width: 90%;
  height: 80vh; /* Adjust this value as needed */

  border-radius: 8px;
  padding: 20px;

  margin: 20px auto; /* Centers the card horizontally */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 40px;
  transform-style: preserve-3d; /* Ensures proper 3D rendering */
  transform-style: preserve-3d; /* Ensures proper 3D rendering */
  transition: transform 0.8s ease-in-out;
}

.card-wrapper {
  transform: rotateY(180deg);
}

.flipped {
  transform: rotateY(180deg);
}

.about {
  grid-column: 1 / 2;
  margin-top: 5rem;
}

.right {
  grid-column: 2 / 3;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.footer {
  flex: 0 0 40%; /* Footer takes 40% of the right section height */
}

.projects {
  flex: 1; /* Projects take the remaining height */
  overflow-y: auto; /* Add scroll if the content overflows */
}

.container {
  width: 100vw; /* Full width of the viewport */
  height: 100vh; /* Full height of the viewport */
  margin: auto;
  justify-content: center;
  align-items: center;
}

.thisProject:hover {
  transform: scale(1.1);
}
